import React from 'react'
import PropTypes from 'prop-types'
import logger from '../../logger'
import * as nrkCoreIcons from '@nrk/core-icons/jsx'

import { NrkLogoNrkSuper, NrkLogoYr } from '@nrk/core-icons/jsx/logo'

import { NrkLogoLargeNrkTv } from '@nrk/core-icons/jsx/logo/large'

// Named imports to enable treeshaking

const coreIcons = { ...nrkCoreIcons, NrkLogoLargeNrkTv, NrkLogoYr, NrkLogoNrkSuper }

const ActualIcon = props => {
  const { id, ...restProps } = props
  if (!id) {
    return null
  }

  const transformedId = id.replace(/(-+|^)./g, m => m.slice(-1).toUpperCase()) // kebab-case to TitleCamelCase
  const Icon = coreIcons[transformedId]

  if (!Icon) {
    logger.warn(`Could not find icon ${transformedId}. Add icon by importing it in CoreIcons`)
    return null
  }

  return <Icon {...restProps} />
}

ActualIcon.propTypes = {
  id: PropTypes.string
}

export default ActualIcon
